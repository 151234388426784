<template>
  <div class="minWidth1000">
    <custom-actions show-back>
      <el-form
        :model="formData"
        ref="formData"
        :rules="fromRules"
        class="form-wrap global-add-info"
        label-width="160px"
      >
        <div class="title-box"  v-if="!isAllEdit || !isEdit">基础信息</div>
        <el-form-item
          label="设备名称"
          prop="deviceName"
          v-if="!isAllEdit && !isEdit"
        >
          <el-input
            type="text"
            v-model="formData.deviceName"
            placeholder="请输入设备名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="设备串号"
          prop="deviceNum"
          v-if="!isAllEdit || !isEdit"
        >
          <el-col :span="9">
            <el-input
            type="text"
            v-model="formData.deviceNum"
            placeholder="请输入设备串号"
          ></el-input>
          </el-col>
          <el-col :span="15">
            <div class="tips-content" >提示：设备串号</div>
          </el-col>
        </el-form-item>
        <el-form-item
          label="所属基站"
          prop="stationId"
          v-if="!isAllEdit && !isEdit"
        >
          <el-cascader
            placeholder="请选择所属基站"
            v-model="formData.stationId"
            :options="deptList"
            :props="{

              expandTrigger: 'hover',
              children: 'childNode',
              label: 'deptName',
              value: 'deptId'
            }"
            @change="changeDept"
          ></el-cascader>
        </el-form-item>
        <!-- <el-form-item
          label="设备位置"
          prop="deviceType"
          v-if="!isAllEdit && !isEdit"
        >
          <el-select v-model="formData.deviceType" placeholder="请选择设备位置">
            <el-option label="基站内" value="0"> </el-option>
            <el-option label="基站外" value="1"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="设备告警阈值"
          prop="deviceWarning"
          v-if="!isAllEdit && !isEdit"
        >
          <el-input
            type="text"
            v-model="formData.deviceWarning"
            placeholder="请输入设备告警阈值"
          ></el-input>
        </el-form-item> -->
        <div class="title-box title-box2">保护设置</div>

        <el-form-item label="瞬时保护" prop="instantaneous1">
          <el-row class="mb10">
            <el-col :span="4">
              <el-switch v-model="isOpen1" @change='changeSwitch1'> </el-switch>
              <span class="tip-switch">{{ isOpen1 ? "打开" : "关闭" }}</span>
            </el-col>
          </el-row>
          <el-row class="">
            <el-col :span="9">
              <el-input
                type="text"
                :disabled="!isOpen1 || formData.instantaneous1 =='0'"
                v-model="formData.instantaneous1"
                placeholder="请输入瞬时保护"
                @input='changeOpen1'
              >
                <template class=" " slot="append">倍</template>
              </el-input>
            </el-col>
            <el-col :span="15">
              <div class="tips-content" >
                {{isOpen1?'提示：超过电流的倍数触发瞬时短路， 范围3~10倍':'提示：关闭状态代表0'}}
              </div>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item prop="instantaneous2">
          是否跳闸
          <el-switch v-model="formData.instantaneous2" :disabled="!isOpen1 || formData.instantaneous1=='0'" active-value="1" inactive-value="0"> </el-switch>
          <span class="tips-content ml15">提示：开启后触发该保护会进行跳闸</span>
        </el-form-item>
        <el-form-item label="短延时保护" prop="shortDelay1">
          <el-row class="mb10">
            <el-col :span="4">
              <el-switch v-model="isOpen2" @change='changeSwitch2'> </el-switch>
              <span class="tip-switch">{{ isOpen2 ? "打开" : "关闭" }}</span>
            </el-col>
          </el-row>
          <el-row class="">
            <el-col :span="9">
              <el-input
                type="text"
                :disabled="!isOpen2 || formData.shortDelay1=='0'"
                 @input='changeOpen2'
                v-model="formData.shortDelay1"
                placeholder="请输入短延时保护"
              >
                <template class=" " slot="append">倍</template>
              </el-input>
            </el-col>
            <el-col :span="15">
              <div class="tips-content" v-if='isOpen2'>
               {{isOpen2?'提示：超过电流的倍数触发短延时短路，范围3~10倍':'提示：关闭状态代表0'}}
              </div>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item prop="shortDelay2">
          是否跳闸
          <el-switch v-model="formData.shortDelay2" :disabled="!isOpen2 || formData.shortDelay1=='0'" active-value="1" inactive-value="0"> </el-switch>
          <span class="tips-content ml15">提示：开启后触发该保护会进行跳闸</span>
        </el-form-item>
        <!-- <div class="form-item-group"> -->

        <el-form-item label="过载保护" prop="overload1">
          <!-- <el-row class="mb10">
            <el-col :span="4">
              <el-switch v-model="isOpen3" @change='changeSwitch3'> </el-switch>
              <span class="tip-switch">{{ isOpen3 ? "打开" : "关闭" }}</span>
            </el-col>
          </el-row>
          <el-row class="">
            <el-col :span="9">
              <el-input
                :disabled="!isOpen3 || formData.overload1=='0'"
                type="text"
                @input='changeOpen3'
                v-model="formData.overload1"
                placeholder="请输入负载个数"
              >
                <template class=" " slot="append">个</template></el-input
              >
            </el-col>
            <el-col :span="15">
              <div class="tips-content" >{{isOpen3?'提示：负载个数':'提示：关闭状态代表0'}}</div>
            </el-col>
          </el-row> -->
          <el-row class="mb10">
            <el-col :span="4">
              <el-switch v-model="isOpen3" @change='changeSwitch3'> </el-switch>
              <span class="tip-switch">{{ isOpen3 ? "打开" : "关闭" }}</span>
            </el-col>
          </el-row>
          <el-row class="">
            <el-col :span="9">
              <el-input
                type="text"
                :disabled="!isOpen3 || formData.overload1=='0'"
                v-model="formData.overload1"
                 @input='changeOpen3'
                placeholder="请输入过电流的数值"
              >
                <template class=" " slot="append">A</template>
              </el-input>
            </el-col>
            <el-col :span="15">
              <div class="tips-content" >{{isOpen3?'提示：过电流的数值(0~80A)':'提示：关闭状态代表0'}}</div>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item prop="overload2">
          <!-- <el-row class="">
            <el-col :span="9">
              <el-input
                type="text"
                :disabled="!isOpen3 || formData.overload2=='0'"
                 @input='changeOpen3'
                v-model="formData.overload2"
                placeholder="请输入负载功率"
              >
                <template class=" " slot="append">Kw</template>
              </el-input>
            </el-col>
            <el-col :span="15">
              <div class="tips-content"  >{{isOpen3?'提示：负载功率(>=100)':'提示：关闭状态代表0'}}</div>
            </el-col>
          </el-row> -->
          <el-row class="">
            <el-col :span="9">
              <el-input
                type="text"
                :disabled="!isOpen3 || formData.overload2=='0'"
                v-model="formData.overload2"
                 @input='changeOpen3'
                placeholder="请输入过载故障延时时间"
              >
                <template class=" " slot="append">s</template>
              </el-input>
            </el-col>
            <el-col :span="15">
              <div class="tips-content">{{isOpen3?'提示：过载故障延时时间(0~30s)':'提示：关闭状态代表0'}}</div>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item prop="overload3">
          是否跳闸
          <el-switch v-model="formData.overload3" :disabled="!isOpen3 || formData.overload1=='0'" active-value="1" inactive-value="0"> </el-switch>
          <span class="tips-content ml15">提示：开启后触发该保护会进行跳闸</span>
        </el-form-item>
        <!-- </div> -->

        <el-form-item label="漏电保护" prop="leakageAction1">
          <el-row class="mb10">
            <el-col :span="24">
              <el-switch v-model="isOpen4" @change='changeSwitch4'> </el-switch>
              <span class="tip-switch">{{ isOpen4 ? "打开" : "关闭" }}</span>
            </el-col>
          </el-row>
          <el-row class="">
            <el-col :span="9">
              <el-input
                :disabled="!isOpen4 || formData.leakageAction1==='0'"
                type="text"
                @input='changeOpen4'
                v-model="formData.leakageAction1"
                placeholder="请输入漏电保护"
              >
                <template class=" " slot="append">mA</template>
              </el-input>
            </el-col>
            <el-col :span="15">
              <div class="tips-content" v-if='isOpen4'>
                {{isOpen4?'提示：漏电保护触发的电流数值(0~500mA)':'提示：关闭状态代表0'}}
              </div>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item prop="overvoltage2">
          是否跳闸
          <el-switch v-model="formData.leakageAction2" :disabled="!isOpen4 || formData.leakageAction1=='0'" active-value="1" inactive-value="0"> </el-switch>
          <span class="tips-content ml15">提示：开启后触发该保护会进行跳闸</span>
        </el-form-item>
        <el-form-item label="过电压保护" prop="overvoltage1">
          <el-row class="mb10">
            <el-col :span="24">
              <el-switch v-model="isOpen5" @change='changeSwitch5'> </el-switch>
              <span class="tip-switch">{{ isOpen5 ? "打开" : "关闭" }}</span>
            </el-col>
          </el-row>
          <el-row class="">
            <el-col :span="9">
              <el-input
                type="text"
                :disabled="!isOpen5 || formData.overvoltage1=='0'"
                v-model="formData.overvoltage1"
                @input='changeOpen5'
                placeholder="请输入过电压保护"
              >
                <template class=" " slot="append">V</template></el-input
              >
            </el-col>
            <el-col :span="15">
              <div class="tips-content">{{isOpen5?'提示：过电压的压电数值(230~300V)':'提示：关闭状态代表0'}}</div>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item prop="overvoltage2">
          是否跳闸
          <el-switch v-model="formData.overvoltage2" :disabled="!isOpen5 || formData.overvoltage1=='0'" active-value="1" inactive-value="0"> </el-switch>
          <span class="tips-content ml15">提示：开启后触发该保护会进行跳闸</span>
        </el-form-item>
        <el-form-item label="欠电压保护" prop="underVoltage1">
          <el-row class="mb10">
            <el-col :span="24">
              <el-switch v-model="isOpen6" @change='changeSwitch6' > </el-switch>
              <span class="tip-switch">{{ isOpen6 ? "打开" : "关闭" }}</span>
            </el-col>
          </el-row>
          <el-row class="">
            <el-col :span="9">
              <el-input
                type="text"
                :disabled="!isOpen6 || formData.underVoltage1=='0'"
                v-model="formData.underVoltage1"
                placeholder="请输入欠电压保护"
                @input='changeOpen6'
              >
                <template class=" " slot="append">V</template></el-input
              >
            </el-col>
            <el-col :span="15">
              <div class="tips-content">{{isOpen6?'提示：欠电压的压电数值(0~200V)':'提示：关闭状态代表0'}}</div>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item prop="underVoltage2">
          是否跳闸
          <el-switch v-model="formData.underVoltage2" :disabled="!isOpen6 || formData.underVoltage1=='0'" active-value="1" inactive-value="0"> </el-switch>
          <span class="tips-content ml15">提示：开启后触发该保护会进行跳闸</span>
        </el-form-item>
        <el-form-item label="超温保护" prop="overTemperature1">
          <el-row class="mb10">
            <el-col :span="24">
              <el-switch v-model="isOpen7" @change="changeSwitch7"> </el-switch>
              <span class="tip-switch">{{ isOpen7 ? "打开" : "关闭" }}</span>
            </el-col>
          </el-row>
          <el-row class="">
            <el-col :span="9">
              <el-input
                type="text"
                :disabled="!isOpen7 || formData.overTemperature1=='0'"
                v-model="formData.overTemperature1"
                placeholder="请输入超温保护 "
                @input='changeOpen7'
              >
                <template class=" " slot="append">℃</template></el-input
              >
            </el-col>
            <el-col :span="15">
              <div class="tips-content" >{{isOpen7?'提示：触发温度保护的值(50~150℃)':'提示：关闭状态代表0'}}</div>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item prop="overTemperature2">
          是否跳闸
          <el-switch v-model="formData.overTemperature2" :disabled="!isOpen7 || formData.overTemperature1=='0'" active-value="1" inactive-value="0"> </el-switch>
          <span class="tips-content ml15">提示：开启后触发该保护会进行跳闸</span>
        </el-form-item>
        <el-form-item label="拉弧保护 " prop="arc1">
          <el-row class="mb10">
            <el-col :span="24">
              <el-switch v-model="isOpen8" @change='changeSwitch8'> </el-switch>
              <span class="tip-switch">{{ isOpen8 ? "打开" : "关闭" }}</span>
            </el-col>
          </el-row>
          <el-row class="">
            <el-col :span="9">
              <el-input
                type="text"
                :disabled="!isOpen8 || formData.arc1 =='0'"
                v-model="formData.arc1"
                @input="changeOpen8"
                placeholder="请输入拉弧动作整定的电流数值"
              >
                <template class=" " slot="append">mA</template></el-input
              >
            </el-col>
            <el-col :span="15">
              <div class="tips-content" >{{isOpen8?'提示：拉弧动作整定的电流数值':'提示：关闭状态代表0'}}</div>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item prop="arc2">
          <el-row class="">
            <el-col :span="9">
              <el-input
                type="text"
                 :disabled="!isOpen8 || formData.arc2=='0'"
                v-model="formData.arc2"
                  @input="changeOpen8"
                placeholder="请输入拉弧动作延时时间"
              >
                <template class=" " slot="append">s</template></el-input
              >
            </el-col>
            <el-col :span="15">
              <div class="tips-content" >提示：{{isOpen8?'拉弧动作延时时间':'关闭状态代表0'}}</div>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item prop="arc3">
          是否跳闸
          <el-switch v-model="formData.arc3" :disabled="!isOpen8 || formData.arc1=='0'" active-value="1" inactive-value="0"> </el-switch>
          <span class="tips-content ml15">提示：开启后触发该保护会进行跳闸</span>
        </el-form-item>

        <el-form-item label="缺相保护 " prop="openPhase1">
          <el-row class="mb10">
            <el-col :span="24">
              <el-switch v-model="isOpen9"  @change='changeSwitch9'> </el-switch>
              <span class="tip-switch">{{ isOpen9 ? "打开" : "关闭" }}</span>
            </el-col>
          </el-row>
          <el-row class="">
            <el-col :span="9">
              <el-input
                type="text"
                :disabled="!isOpen9 || formData.openPhase1=='0'"
                v-model="formData.openPhase1"
                  @input="changeOpen9"
                placeholder="请输入缺相保护"
              >
                <template class=" " slot="append">s</template></el-input
              >
            </el-col>
            <el-col :span="15">
              <div class="tips-content" >{{isOpen9?'提示：缺相延时时间':'提示：关闭状态代表0'}}</div>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item prop="openPhase2">
          是否跳闸
          <el-switch v-model="formData.openPhase2" :disabled="!isOpen9 || formData.openPhase1=='0'" active-value="1" inactive-value="0"> </el-switch>
          <span class="tips-content ml15">提示：开启后触发该保护会进行跳闸</span>
        </el-form-item>
        <el-form-item label="自动分合闸" prop="autoPower2">
          <el-row class="mb10">
            <el-col :span="24">
              <el-switch v-model="isOpen12" @change="changeSwitch12"> </el-switch>
              <span class="tip-switch">{{ isOpen12 ? "打开" : "关闭" }}</span>
            </el-col>
          </el-row>
          <el-row class="">
            <el-col :span="9">
              <el-time-picker
                 format="HH:mm"
                 :disabled="!isOpen12"
                 v-model="formData.autoClosingPower1"
                placeholder="请选择自动分闸时间"
              >
              </el-time-picker>
            </el-col>
            <el-col :span="15">
              <div class="tips-content" >提示：{{isOpen12?'自动分闸时间':'关闭状态'}}</div>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item prop="autoPower">
          <el-row class="">
            <el-col :span="9">
              <el-time-picker
                format="HH:mm"
                :disabled="!isOpen12"
                v-model="formData.autoOpeningPower1"
                placeholder="请选择自动合闸时间"
              >
               </el-time-picker>
            </el-col>
            <el-col :span="15">
              <div class="tips-content" >{{isOpen12?'提示：自动合闸时间':'提示：关闭状态'}}</div>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <div class="button-box">
        <el-button
          type="primary"
          class="global-button mr10"
          @click="save"
          :disabled="loading"
          >提交</el-button
        >
        <el-button class="global-button" @click="$router.back()"
          >取消</el-button
        >
      </div>
    </custom-actions>
  </div>
</template>
<script>
export default {
  name: 'DeviceAdd',
  data () {
    const validateOvervoltage1 = (rule, value, callback) => {
      if (this.formData.overvoltage1) {
        if (Number(this.formData.overvoltage1) === 0) {
          return callback()
        }
        if (
          !/^[0-9]*$/.test(this.formData.overvoltage1) ||
          this.formData.overvoltage1 < 230 ||
          this.formData.overvoltage1 > 300
        ) {
          return callback(new Error('请输入0或230~300之间的整数'))
        }
      }
      return callback()
    }
    const validateOverTemperature1 = (rule, value, callback) => {
      if (this.formData.overTemperature1) {
        if (Number(this.formData.overTemperature1) === 0) {
          return callback()
        }
        if (
          !/^[0-9]*$/.test(this.formData.overTemperature1) ||
          this.formData.overTemperature1 < 50 ||
          this.formData.overTemperature1 > 500
        ) {
          return callback(new Error('请输入0或50~500之间的整数'))
        }
      }
      return callback()
    }

    const validateOpenPhase = (rule, value, callback) => {
      if (this.formData.openPhase1) {
        if (
          !/^[0-9]*$/.test(this.formData.openPhase1) ||
          this.formData.openPhase1 < 0 ||
          this.formData.openPhase1 > 3600
        ) {
          return callback(new Error('请输入0~3600之间的整数'))
        }
      }
      return callback()
    }

    const validateArc1 = (rule, value, callback) => {
      if (this.formData.arc1) {
        if (
          !/^[0-9]*$/.test(this.formData.arc1) ||
          this.formData.arc1 < 0 ||
          this.formData.arc1 > 3600
        ) {
          return callback(new Error('请输入0~3600之间的整数'))
        }
      }
      return callback()
    }
    const validateArc2 = (rule, value, callback) => {
      if (this.formData.arc2) {
        if (
          !/^[0-9]*$/.test(this.formData.arc2) ||
          this.formData.arc12 < 0 ||
          this.formData.arc2 > 3600
        ) {
          return callback(new Error('请输入0~3600之间的整数'))
        }
      }
      return callback()
    }
    const validateAutoPower = (rule, value, callback) => {
      if (this.isOpen12) {
        if (this.formData.autoOpeningPower1 === '') {
          return callback(new Error('请选择合闸时间'))
        }
      }
      return callback()
    }
    const validateAutoPower2 = (rule, value, callback) => {
      if (this.isOpen12) {
        if (this.formData.autoClosingPower1 === '') {
          return callback(new Error('请选择分闸时间'))
        }
      }
      return callback()
    }
    return {
      loading: false,
      isAllEdit: false,
      isEdit: false,
      isOpen1: true,
      isOpen2: true,
      isOpen3: true,
      isOpen4: true,
      isOpen5: true,
      isOpen6: true,
      isOpen7: true,
      isOpen8: true,
      isOpen9: true,
      isOpen10: false,
      isOpen11: false,
      isOpen12: false,
      formData: {
        deviceName: '',
        stationId: '',
        // deviceType: '',
        deviceNum: '',
        // deviceWarning: '',
        instantaneous1: '',
        instantaneous2: '',
        shortDelay1: '',
        shortDelay2: '',
        overload: '',
        overload1: '',
        overload2: '',
        overload3: '',
        leakageAction1: '',
        leakageAction2: '',
        overvoltage1: '',
        overvoltage2: '',
        underVoltage1: '',
        underVoltage2: '',
        overTemperature1: '',
        overTemperature2: '',
        arc: '',
        arc1: '',
        arc2: '',
        arc3: '',
        v46: '',
        v461: '',
        v462: '',
        openPhase1: '',
        openPhase2: '',
        antiStealing: '',
        autoOpeningPower1: '',
        autoClosingPower1: '',
        autoOpeningPower: '',
        autoClosingPower: ''
      },

      fromRules: {
        deviceName: [
          { required: true, message: '请输入设备名称', trigger: 'blur' }
        ],
        deviceNum: [
          { required: true, message: '请输入设备串号', trigger: 'blur' }
        ],
        stationId: [
          { required: true, message: '请选择所属基站', trigger: 'change' }
        ],
        // deviceType: [
        //   {
        //     required: true,
        //     message: '请选择设备位置',
        //     trigger: 'change'
        //   }
        // ],

        // deviceWarning: [
        //   { required: true, message: '请输入设备告警阈值', trigger: 'blur' }
        // ],
        instantaneous1: [
          { required: true, message: '请输入瞬时保护', trigger: 'blur' },
          { pattern: /^(0|[3-9]|10)$/, message: '只能输入0或3~10之间的整数' }
        ],
        shortDelay1: [
          { required: true, message: '请输入短延时保护', trigger: 'blur' },
          { pattern: /^(0|[3-9]|10)$/, message: '只能输入0或3~10之间的整数' }
        ],

        // overload1: [
        //   {
        //     required: true,
        //     message: '请输入负载个数',
        //     trigger: 'blur'
        //   },
        //   { pattern: /^(0|([1-9]\d*))$/, message: '请输入0或大于等于0的整数' }
        // ],
        // overload2: [
        //   {
        //     required: true,
        //     message: '请输入负载功率',
        //     trigger: 'blur'
        //   },
        //   {
        //     pattern: /^(0|[1-9]\d{2,})$/,
        //     message: '请输入0或大于等于100的整数'
        //   }
        // ],
        overload1: [
          {
            required: true,
            message: '请输入过电流的数值',
            trigger: 'blur'
          },
          {
            pattern: /^(?:[0-9]|[1-7][0-9]|80)$/,
            message: '请输入0~80之间的整数'
          }
        ],
        overload2: [
          {
            required: true,
            message: '请输入过载故障延时时间',
            trigger: 'blur'
          },
          {
            pattern: /^(0|(?:[0-9]|[1-2][0-9]|30))$/,
            message: '请输入0~30之间的整数'
          }
        ],
        leakageAction1: [
          { required: true, message: '请输入漏电保护', trigger: 'blur' },
          {
            pattern: /^(0|500|[1-4]{0,1}\d{0,1}\d)$/,
            message: '只能输入0到500之间的整数'
          }
        ],
        overvoltage1: [
          // 230~300 /^\d+$/g
          { required: true, message: '请输入过电压保护', trigger: 'blur' },

          {
            validator: validateOvervoltage1,
            trigger: 'blur'
          }
        ],
        underVoltage1: [
          { required: true, message: '请输入欠电压保护', trigger: 'blur' },
          {
            pattern: /^([0-9]|[1-9]\d|1\d{2}|200)$/,
            message: '只能输入0到200之间的整数'
          }
        ],
        overTemperature1: [
          // 50~150
          { required: true, message: '请输入超温保护', trigger: 'blur' },

          {
            validator: validateOverTemperature1,
            trigger: 'blur'
          }
        ],

        arc1: [
          // 0-3600
          {
            required: true,
            message: '请输入拉弧动作整定的电流数值',
            trigger: 'blur'
          },
          {
            validator: validateArc1,
            trigger: 'blur'
          }
        ],
        arc2: [
          // 0-3600
          {
            required: true,
            message: '请输入拉弧动作延时时间',
            trigger: 'blur'
          },
          {
            validator: validateArc2,
            trigger: 'blur'
          }
        ],
        openPhase1: [
          // 0-3600
          { required: true, message: '请输入缺相保护', trigger: 'blur' },

          {
            validator: validateOpenPhase,
            trigger: 'blur'
          }
        ],
        autoPower: [
          {
            validator: validateAutoPower,
            trigger: 'change'
          }
        ],
        autoPower2: [
          {
            validator: validateAutoPower2,
            trigger: 'change'
          }
        ]
      },
      deptList: []
    }
  },
  created () {
    this.getDeptList()

    const id = this.$route.params.id || ''
    const deviceNumArr = this.$route.query.list || ''

    // 编辑
    if (id) {
      this.getDetail(id)
      this.isEdit = !!id
    }
    if (deviceNumArr) {
      this.isEdit = true
      this.isAllEdit = !!(deviceNumArr && deviceNumArr.length > 0)
    }
  },

  methods: {
    getDeptList () {
      this.$axios.get(this.$apis.system.selectSysDeptByList).then(res => {
        if (res) {
          this.deptList = res
        }
      })
    },
    parseTimestr (str) {
      const result = [str.slice(0, 2), str.slice(2)]
      return result[0] + ':' + result[1]
    },
    getDetail (deviceNum) {
      this.$axios
        .get(this.$apis.smartDevice.selectDeviceByDeviceNum, {
          params: { deviceNum }
        })
        .then(res => {
          console.log('res: ', res)
          res.v46 = res.v46 || '0_0' // 防止这个新加的字段为空
          res.openPhase = res.openPhase || '0_0' //
          res.instantaneous = res.instantaneous || '0_0' //
          res.leakageAction = res.leakageAction || '0_0' //
          res.shortDelay = res.shortDelay || '0_0'
          res.overvoltage = res.overvoltage || '0_0' // 防止这个新加的字段为空
          res.overTemperature = res.overTemperature || '0_0' // 防止这个新加的字段为空
          res.underVoltage = res.underVoltage || '0_0' // 防止这个新加的字段为空
          res.instantaneous1 = res.instantaneous.split('_')[0] || ''
          res.instantaneous2 = res.instantaneous.split('_')[1] || '0'
          res.shortDelay1 = res.shortDelay.split('_')[0] || ''
          res.shortDelay2 = res.shortDelay.split('_')[1] || '0'
          res.leakageAction1 = res.leakageAction.split('_')[0] || ''
          res.leakageAction2 = res.leakageAction.split('_')[1] || '0'
          res.overload1 = res.overload.split('_')[0] || ''
          res.overload2 = res.overload.split('_')[1] || ''
          res.overload3 = res.overload.split('_')[2] || '0'
          // res.overload4 = res.overload.split('_')[3] || ''
          // res.overload5 = res.overload.split('_')[4] || '0'
          res.overvoltage1 = res.overvoltage.split('_')[0] || ''
          res.overvoltage2 = res.overvoltage.split('_')[1] || '0'
          res.underVoltage1 = res.underVoltage.split('_')[0] || ''
          res.underVoltage2 = res.underVoltage.split('_')[1] || '0'
          res.overTemperature1 = res.overTemperature.split('_')[0] || ''
          res.overTemperature2 = res.overTemperature.split('_')[1] || '0'
          res.arc1 = res.arc.split('_')[0] || ''
          res.arc2 = res.arc.split('_')[1] || ''
          res.arc3 = res.arc.split('_')[2] || '0'
          res.openPhase1 = res.openPhase.split('_')[0] || ''
          res.openPhase2 = res.openPhase.split('_')[1] || '0'
          res.v461 = res.v46.split('_')[0] || ''
          res.v462 = res.v46.split('_')[1] || ''
          res.autoOpeningPower1 = this.$dayjs('1970-00-00 ' + this.parseTimestr(res.autoOpeningPower.split('_')[0]), 'YYYY-MM-DD HH:mm').toDate()
          res.autoClosingPower1 = this.$dayjs('1970-00-00 ' + this.parseTimestr(res.autoClosingPower.split('_')[0]), 'YYYY-MM-DD HH:mm').toDate()
          this.formData = res
          this.isOpen1 = res.instantaneous !== '0_0'
          this.isOpen2 = res.shortDelay !== '0_0'
          this.isOpen3 = res.overload !== '0_0_0'
          this.isOpen4 = res.leakageAction !== '0_0'
          this.isOpen5 = res.overvoltage !== '0_0'
          this.isOpen6 = res.underVoltage !== '0_0'
          this.isOpen7 = res.overTemperature !== '0_0'
          this.isOpen8 = res.arc !== '0_0_0'
          this.isOpen9 = res.openPhase !== '0_0'
          this.isOpen10 = res.antiStealing !== '0'
          this.isOpen11 = res.v46 !== '0_0'
          this.isOpen12 = res.autoOpeningPower.split('_')[1] !== '0'
        })
    },
    save () {
      console.log('--------', this.formData)

      this.formData.instantaneous = this.isOpen1 ? this.formData.instantaneous1 + '_' + this.formData.instantaneous2 : '0_0'
      this.formData.shortDelay = this.isOpen2 ? this.formData.shortDelay1 + '_' + this.formData.shortDelay2 : '0_0'
      this.formData.overload = this.isOpen3 ? this.formData.overload1 + '_' + this.formData.overload2 + '_' + this.formData.overload3 : '0_0_0_0'
      this.formData.leakageAction = this.isOpen4 ? this.formData.leakageAction1 + '_' + this.formData.leakageAction2 : '0_0'
      this.formData.overvoltage = this.isOpen5 ? this.formData.overvoltage1 + '_' + this.formData.overvoltage2 : '0_0'
      this.formData.underVoltage = this.isOpen6 ? this.formData.underVoltage1 + '_' + this.formData.underVoltage2 : '0_0'
      this.formData.overTemperature = this.isOpen7 ? this.formData.overTemperature1 + '_' + this.formData.overTemperature2 : '0_0'
      this.formData.arc = this.isOpen8 ? this.formData.arc1 + '_' + this.formData.arc2 + this.formData.arc3 : '0_0'
      this.formData.v46 = '0_0'
      this.formData.openPhase = this.isOpen9 ? this.formData.openPhase1 + '_' + this.formData.openPhase2 : '0_0'
      this.formData.antiStealing = '0'
      this.formData.autoOpeningPower = this.isOpen12 ? this.$dayjs(this.formData.autoOpeningPower1).format('HHmm') + '_1' : this.$dayjs(this.formData.autoOpeningPower1).format('HHmm') + '_0'
      this.formData.autoClosingPower = this.isOpen12 ? this.$dayjs(this.formData.autoClosingPower1).format('HHmm') + '_1' : this.$dayjs(this.formData.autoClosingPower1).format('HHmm') + '_0'
      const showList = this.$route.params.showList || 0

      this.$refs.formData.validate(valid => {
        if (valid) {
          this.loading = true
          this.formData.openPhase = `${this.formData.openPhase1}_${this.formData.openPhase2}`
          this.formData.instantaneous = `${this.formData.instantaneous1}_${this.formData.instantaneous2}`
          this.formData.shortDelay = `${this.formData.shortDelay1}_${this.formData.shortDelay2}`
          this.formData.leakageAction = `${this.formData.leakageAction1}_${this.formData.leakageAction2}`
          this.formData.overload = `${this.formData.overload1}_${this.formData.overload2}_${this.formData.overload3}`
          this.formData.overvoltage = `${this.formData.overvoltage1}_${this.formData.overvoltage2}`
          this.formData.underVoltage = `${this.formData.underVoltage1}_${this.formData.underVoltage2}`
          this.formData.overTemperature = `${this.formData.overTemperature1}_${this.formData.overTemperature2}`
          this.formData.arc = `${this.formData.arc1}_${this.formData.arc2}_${this.formData.arc3}`
          this.formData.v46 = `${this.formData.v461}_${this.formData.v462}`
          // delete this.formData.overload1
          // delete this.formData.overload2
          // delete this.formData.overload3
          // delete this.formData.overload4
          // delete this.formData.arc1
          // delete this.formData.arc2

          // const tempForm = Object.assign({}, this.formData)
          const tempForm = JSON.parse(JSON.stringify(this.formData))
          delete tempForm.instantaneous1
          delete tempForm.instantaneous2
          delete tempForm.shortDelay1
          delete tempForm.shortDelay2
          delete tempForm.leakageAction1
          delete tempForm.leakageAction2
          delete tempForm.overload1
          delete tempForm.overload2
          delete tempForm.overload3
          delete tempForm.overvoltage1
          delete tempForm.overvoltage2
          delete tempForm.underVoltage1
          delete tempForm.underVoltage2
          delete tempForm.overTemperature1
          delete tempForm.overTemperature2
          delete tempForm.openPhase1
          delete tempForm.openPhase2
          delete tempForm.arc1
          delete tempForm.arc2
          delete tempForm.arc3
          delete tempForm.v461
          delete tempForm.v462
          delete tempForm.autoOpeningPower1
          delete tempForm.autoClosingPower1

          console.log('--------', this.formData)
          if (this.isAllEdit) {
            // 批量修改
            tempForm.deviceNumList = this.$route.query.list || []
            delete tempForm.deviceName
            delete tempForm.deviceNum
            delete tempForm.stationId
            this.$axios
              .post(this.$apis.smartDevice.updateAllDevice, tempForm)
              .then(res => {
                this.$message.success('提交成功')
                this.$router.push({ name: 'smartDeviceList' })
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            const url = this.$route.params.id
              ? this.$apis.smartDevice.updateDevice
              : this.$apis.smartDevice.insertDevice
            this.$axios
              .post(url, tempForm)
              .then(res => {
                this.$message.success('提交成功')
                this.$router.push({ name: 'smartDeviceList', query: { showList } })
              })
              .finally(() => {
                this.loading = false
              })
          }
        }
      })
    },
    changeDept (val) {
      if (val && val.length > 0) {
        this.formData.stationId = val[val.length - 1]
      }
    },
    changeOpen1 (val) {
      this.isOpen1 = val !== '0'
      if (!this.isOpen1) {
        this.formData.instantaneous1 = '0'
        this.formData.instantaneous2 = '0'
      }
    },
    changeSwitch1 (val) {
      this.formData.instantaneous1 = val ? '' : '0'
      this.formData.instantaneous2 = '0'
    },
    changeOpen2 (val) {
      this.isOpen2 = val !== '0'
      if (!this.isOpen2) {
        this.formData.shortDelay1 = '0'
        this.formData.shortDelay2 = '0'
      }
    },
    changeSwitch2 (val) {
      this.formData.shortDelay1 = val ? '' : '0'
      this.formData.shortDelay2 = '0'
    },
    changeOpen3 (val) {
      this.isOpen3 = val !== '0'
      if (!this.isOpen3) {
        this.formData.overload1 = '0'
        this.formData.overload2 = '0'
        this.formData.overload3 = '0'
      }
    },
    changeSwitch3 (val) {
      this.formData.overload1 = val ? '' : '0'
      this.formData.overload2 = val ? '' : '0'
      this.formData.overload3 = '0'
    },
    changeOpen4 (val) {
      this.isOpen4 = val !== '0'
      if (!this.isOpen4) {
        this.formData.leakageAction1 = '0'
        this.formData.leakageAction2 = '0'
      }
    },
    changeSwitch4 (val) {
      this.formData.leakageAction1 = val ? '' : '0'
      this.formData.leakageAction2 = '0'
    },
    changeOpen5 (val) {
      this.isOpen5 = val !== '0'
      if (!this.isOpen5) {
        this.formData.overvoltage1 = '0'
        this.formData.overvoltage2 = '0'
      }
    },
    changeSwitch5 (val) {
      this.formData.overvoltage1 = val ? '' : '0'
      this.formData.overvoltage2 = '0'
    },
    changeOpen6 (val) {
      this.isOpen6 = val !== '0'
      if (!this.isOpen6) {
        this.formData.underVoltage1 = '0'
        this.formData.underVoltage2 = '0'
      }
    },
    changeSwitch6 (val) {
      this.formData.underVoltage1 = val ? '' : '0'
      this.formData.underVoltage2 = '0'
    },
    changeOpen7 (val) {
      this.isOpen7 = val !== '0'
      if (!this.isOpen7) {
        this.formData.overTemperature1 = '0'
        this.formData.overTemperature2 = '0'
      }
    },
    changeSwitch7 (val) {
      this.formData.overTemperature1 = val ? '' : '0'
      this.formData.overTemperature2 = '0'
    },
    changeOpen8 (val) {
      this.isOpen8 = val !== '0'
      if (!this.isOpen8) {
        this.formData.arc1 = '0'
        this.formData.arc2 = '0'
        this.formData.arc3 = '0'
      }
    },
    changeSwitch8 (val) {
      this.formData.arc1 = val ? '' : '0'
      this.formData.arc2 = val ? '' : '0'
      this.formData.arc3 = '0'
    },
    changeOpen9 (val) {
      this.isOpen9 = val !== '0'
      if (!this.isOpen9) {
        this.formData.openPhase1 = '0'
        this.formData.openPhase2 = '0'
      }
    },
    changeSwitch9 (val) {
      this.formData.openPhase1 = val ? '' : '0'
      this.formData.openPhase2 = '0'
    },
    changeOpen10 (val) {
      this.isOpen10 = val !== '0'
    },
    changeSwitch10 (val) {
      this.formData.antiStealing = val ? '' : '0'
    },
    changeOpen11 (val) {
      this.isOpen11 = val !== '0'
      if (!this.isOpen11) {
        this.formData.v461 = '0'
        this.formData.v462 = '0'
      }
    },
    changeSwitch11 (val) {
      this.formData.v461 = val ? '' : '0'
      this.formData.v462 = val ? '' : '0'
    },
    changeSwitch12 (val) {
      // this.formData.autoOpeningPower1 = ''
      // this.formData.autoClosingPower1 = ''
    }

  }
}
</script>
<style lang="scss" scoped>
.flex-item {
  display: flex;
  .el-form-item__content {
    display: flex;
  }
  .line {
    margin: 0 10px;
  }
}

.el-input,
.el-textarea,
.el-select,
.el-cascader {
  width: 320px;
}

.form-wrap {
  margin: 0px 60px 0 60px;
  // width: 860px;
  // overflow-y: auto;
  // margin: 0 auto;
}
.button-box {
  padding: 30px 210px 50px 200px;
  display: flex;
  align-items: center;
  .btn {
    width: 120px;
    height: 40px;
    border-radius: 2px;
    font-size: 14px;
  }
}

.close-icon {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  color: #409eff;
  z-index: 999;
  font-size: 18px;
  cursor: pointer;
}
.upload-wrap {
  display: flex;
  .img-wrap {
    display: flex;
    flex-wrap: wrap;
    .box-img {
      position: relative;
      border: 1px dashed #d9d9d9;
      margin-right: 15px;
    }
  }
}
.form-item-group {
  display: flex;
  .el-form-item {
    flex: 1;
    ::v-deep .el-form-item__content {
      width: 175px;
    }
  }
  &.form-item-group2 {
    ::v-deep .el-form-item__content {
      width: 357px;
    }
  }
}
.tip-switch {
  margin-left: 10px;
}
.title-box {
  font-size: 14px;
  margin: 10px 0 30px 0;
  padding-left: 10px;
  position: relative;
  &.title-box2 {
    margin-top: 40px;
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    height: 14px;
    background: #007165;
  }
}
::v-deep .el-input-group__append {
  width: 30px;
  padding: 0;
  margin: 0;
  text-align: center;
  font-size: 12px;
  border-radius: 0 2px 2px 0;
}
.tips-content {
  font-size: 12px;
  color: #666;
}
</style>
